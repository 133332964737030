<template>
  <div class="hot">
    <div class="information-haeder-box">
      <div class="hot-header">
        <div class="hot-header-left">
          <span class="hot-header-title">媒体热榜</span>
          <!-- <span @click="$router.push('/hotData/history')">历史涉及本机构榜单</span> -->
          <!-- <span class="hot-header-time">（2021年06月01日）</span> -->
        </div>
        <div class="hot-header-right">
          <span class="hot-header-flush" @click="handleUpate">
            <img src="../../assets/img/views/flush.png" /> 数据刷新
          </span>
          <span class="hot-header-set" @click="pushSet">
            <img src="../../assets/img/views/set.png" />设置显示推送
          </span>
        </div>
      </div>
    </div>
    <div style="height:60px"></div>
    <!-- 今日上榜 -->
    <div id="zb_hotdata_today" v-if="hotDataShow">
      <div class="zb_hotdata_title">
        <div>
          <img src="../../assets/img/views/monitoringScheme/quanquan.png" />
          今日上榜
          <span style="font-size:14px;color:#666">（机构区域关键词上榜热搜分布）</span>
        </div>
        <span class="zb_hotdata_today_history" @click="$router.push('/hotData/history')">历史涉及本机构榜单</span>
      </div>
      <el-table class="zb_hotdata_today_table" :data="hotTableData"
        :header-cell-style="{ textAlign: 'center', color: 'black', backgroundColor: '#f5f6fa' }"
        :cell-style="{ height: '65px' }" height="375" stripe>
        <el-table-column label="热榜平台" width="250">
          <template slot-scope="scope">
            <div class="zb_hotdata_today_platform">
              <img :src="hotTypeImgList[scope.row.hotType]">
              <span>{{ scope.row.hotTypeName }} - {{ scope.row.areaName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="最高排名" width="100">
          <template slot-scope="scope">
            <div class="zb_hotdata_today_rank">
              {{ scope.row.maxRanking ? scope.row.maxRanking : "top" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="热榜标题" width="350">
          <template slot-scope="scope">
            <div class="zb_hotdata_today_title" @click="gourlPath(scope.row.urlPath)">
              {{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="最高热度" width="150">
          <template slot-scope="scope">
            <div class="zb_hotdata_today_Clicks">
              {{ scope.row.maxRanking == 0 ? '置顶热榜' : formatNumber(scope.row.maxClicks) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="releaseTime" label="上榜时间" align="center"></el-table-column>
        <el-table-column label="退榜时间">
          <template slot-scope="scope">
            <div class="zb_hotdata_today_time">
              {{ scope.row.withdrawalTime ? scope.row.withdrawalTime : "暂未退榜" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="durationTime" label="持续时长" align="center"></el-table-column>
      </el-table>
      <div>
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="page.currentPage"
          :page-size="page.pageSize" layout="total, prev, pager, next" :total="page.total">
        </el-pagination>
      </div>
    </div>
    <div v-loading="loadingList" class="hot-container">
      <div class="hot-cont" v-for="(val, index) in carouselData" :key="index" v-loading="pushSetData.loading">
        <div style="width: 100%; height: 100%; overflow: hidden">
          <div @click="changeData(val, index)" class="change-title">
            <i class="el-icon-refresh"></i>换一换
          </div>
          <div class="carousel-name">
            <img v-if="val.listName == '头条热榜' || val.listName == '今日头条-全国' || val.listName == '今日头条'"
              src="../../assets/img/views/toutiao.png" />
            <img v-if="val.listName == '抖音热榜' || val.listName == '抖音热榜（社会）'" src="../../assets/img/views/douyin.png" />
            <img v-if="val.listName == '百度热榜'" src="../../assets/img/views/baidu.png" />
            <img v-if="val.listName == '贴吧热议榜'" src="../../assets/img/views/tieba.png" />
            <img v-if="val.listName == '知乎热榜'" src="../../assets/img/views/zhihu.png" />
            <img v-if="val.listName == '新浪微博'" src="../../assets/img/views/xl.png" />
            <img v-if="val.listName == '哔哩哔哩'" src="../../assets/img/views/bl.png" />
            <img v-if="val.listName == '新浪新闻'" src="../../assets/img/views/xlxw.png" style="height:28px"/>
            <span>
              {{
                val.listName + (val.areaName ? "-" + val.areaName : "")
              }}
            </span>
          </div>
          <ul v-loading="val.loading" class="carousel-list" v-if="val.hostList && val.hostList.length != 0">
            <!-- <li
            :class="{
             // 'carousel-list-li': val.type == 1,
              'carousel-list-text-li': val.type == 2,
            }"
            v-for="(val2, index2) in val.data"
            :key="index2"
            >-->
            <li class="carousel-list-text-li" v-for="(val2, index2) in val.hostList" :key="index2">
              <!-- <img src="../../assets/img/views/1.jpg" v-if="val.type == 1" /> -->
              <!-- <div class="carousel-list-text" v-if="val.type ==hotListInfohotListInfo 1">
              <p>{{ val2.title }}</p>
              <p>
                <span>{{ val2.read }}</span>
                &nbsp;
                <span>{{ val2.discuss }}</span>
              </p>
              </div>-->
              <!-- <span class="carousel-list-text-index" v-if="val.type == 2"> -->
              <span class="carousel-list-text-index">
                {{
                  val.pageNum > 1
                  ? (val.pageNum - 1) * 10 + index2 + 1
                  : index2 + 1
                }}
              </span>
              <!-- <span class="carousel-list-text-title" v-if="val.type == 2"> -->
              <span @click="handel(val2.urlPath)" class="carousel-list-text-title">{{ val2.title }}</span>
              <img class="carousel-list-img" v-if="val2.states == '火'" src="../../assets/img/views/huo.png" alt />
              <img class="carousel-list-img" v-if="val2.states == '热'" src="../../assets/img/views/re.png" alt />
              <img class="carousel-list-img" v-if="val2.states == '爆'" src="../../assets/img/views/bao.png" alt />
              <img class="carousel-list-img" v-if="val2.states == '新'" src="../../assets/img/views/xin.png" alt />

              <!-- <span class="carousel-list-text-read" v-if="val.type == 2"> -->

              <!-- <span
                class="carousel-list-text-read"
              >{{ val.hostTypeId == 1? val2.heatIndex : val.hostTypeId == 2 ? val2.readVolume:val.hostTypeId == 3 ?val2.readVolume :val.hostTypeId == 4 ? val2.pageviews : val.hostTypeId == 5 ? val2.degreeHeat : val.hostTypeId == 6 ? val2.readVolume :val.hostTypeId == 7 ? val2.degreeHeat :val.hostTypeId == 8 ? val2.degreeHeat :val.hostTypeId == 9 ? val2.degreeHeat : '' }}</span>-->
              <span class="carousel-list-text-read">
                {{
                  val2.clicks > 99999
                  ? parseInt((val2.clicks / 10000) * 10) / 10 + "万"
                  : val2.clicks
                }}
              </span>
            </li>
          </ul>
          <div class="analyse-container" v-else>
            <div class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="pushSetData.dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      :before-close="handleClose" width="800px">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">热榜显示及推送设置</span>
      </div>
      <div class="dialog-body">
        <div class="hot-pushSetData-top">
          <div class="hot-pushSetData-left" v-loading="loading">
            <p class="el-dialog-subhead">热榜设置</p>
            <div class="countryList">
              <p>全国榜单</p>
              <div>
                <el-checkbox-group v-model="pushSetData.data.val3">
                  <el-checkbox v-for="city in countryList" :label="city" :key="city.hotListId"
                    :disabled="pushSetData.data.val3.length >= 10">{{ city.hotName + "-" + city.area }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="hot-pushSetData-left-option">
              <div class="local-text">地方榜单</div>
              <el-input clearable size="medium" style="width:370px;margin-right:16px" placeholder="榜单名称或区域模糊"
                prefix-icon="el-icon-search" v-model="searchList"></el-input>
              <el-button type="primary" style="width: 80px" size="medium" @click="search">查询</el-button>
              <div class="option-group-big">
                <div class="option-group-box">
                  <div class="option-group" v-for="(val, index) in pushSetData.options" :key="index">
                    <el-checkbox-group v-model="pushSetData.data.val3">
                      <el-checkbox v-for="city in val.name1" :label="city" :key="city.hotListId"
                        :disabled="pushSetData.data.val3.length >= 10">{{ city.hotName + "-" + city.area }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hot-pushSetData-right">
            <p class="el-dialog-subhead">
              已选择热榜
              <span class="el-dialog-subhead-remark">{{ pushSetData.data.val3.length }}/10</span>
            </p>
            <div class="hot-pushSetData-ul">
              <div class="hot-pushSetData-ul-li" v-for="(val, index) in pushSetData.data.val3" :key="index">
                <span class="hot-pushSetData-ul-li-name">
                  {{
                    val.area + val.hotName
                  }}
                </span>
                <img src="../../assets/img/views/cancel.png" @click="removePushSet(index)" />
              </div>
            </div>
            <div class="pushSet">推送设置</div>
            <div>
              <el-radio v-model="pushSetData.data.val1" label="1">不接收</el-radio>
              <el-radio v-model="pushSetData.data.val1" label="2">接收推送</el-radio>
            </div>
            <el-checkbox-group class="pushSet-box" v-model="pushSetData.data.val2" v-if="pushSetData.data.val1 == '2'">
              <el-checkbox :label="item" v-for="item in pushSetData.data.val4" :key="item.setId">{{ item.timeValue
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="handleClose">取消</el-button>
        <el-button size="medium" type="primary" @click="editHotList">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  hotList,
  changeInfo,
  selectList,
  changeData,
  todayHotRank,
  selectlocal
} from "../../api/hotData/list";
export default {
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      loadingList: false,
      loading: false,
      carouselData: [],
      // 换一换的时候用于请求分页
      changNum: 1,
      pushSetData: {
        loading: false,
        dialogVisible: false,
        options: [
          {
            type: "1",
            name1: []
          },
          {
            type: "2",
            name1: []
          },
          {
            type: "3",
            name1: []
          },
          {
            type: "4",
            name1: []
          },
          {
            type: "5",
            name1: []
          },
          {
            type: "6",
            name1: []
          },
          {
            type: "7",
            name1: []
          },
          {
            type: "8",
            name1: []
          },
          {
            type: "9",
            name1: []
          },
          {
            type: "10",
            name1: []
          },
          {
            type: "11",
            name1: []
          }
        ],
        data: {
          val1: "1",
          val2: [],
          val3: [],
          val4: []
        }
      },
      everyitem: [],
      pushSetId: null, //热榜推送设置主键id
      listName: [],
      //   搜索框
      searchList: "",
      countryList: [], // 存放全国的列表
      hotTableData: [], //今日上榜数据
      hotTypeList: {
        1: '百度',
        2: '今日头条(全国)',
        3: '今日头条(本地)',
        4: '知乎',
        5: '快手',
        6: '新浪',
        7: '抖音(热榜)',
        8: '抖音(社会)',
        9: '抖音(本地)',
        10: '哔哩哔哩'
      },
      hotTypeImgList: {
        1: require('.././../assets/img/views/meiti/baidu.png'),
        2: require('.././../assets/img/views/meiti/toutiao.png'),
        3: require('.././../assets/img/views/meiti/toutiao.png'),
        4: require('.././../assets/img/views/meiti/zhihu.png'),
        5: require('.././../assets/img/views/meiti/kuaishou.png'),
        6: require('.././../assets/img/views/meiti/weibo.png'),
        7: require('.././../assets/img/views/meiti/douyin.png'),
        8: require('.././../assets/img/views/meiti/douyin.png'),
        9: require('.././../assets/img/views/meiti/douyin.png'),
        10: require('.././../assets/img/views/meiti/bzhan.png'),
      },
      hotDataShow: false
    };
  },
  created() {
    // 查看用户设置过的数据
    this.hotListInfo();
    this.getHotData();
  },
  methods: {
    // 计算万单位
    formatNumber(num) {
      num = Number(num);
      if (num == 0) {
        return num + '';
      } else
        if (num > 1 && num < 10000) {
          return num + '';
        } else {
          return (num / 10000).toFixed(2) + '万';
        }
    },
    //   关闭弹框
    handleClose() {
      this.pushSetData.dialogVisible = false;
      this.searchList = "";
    },
    //   查询功能
    search() {
      this.pushSetData.options.forEach(ite => {
        ite.name1 = [];
      });
      let dataList = [];
      this.everyitem.forEach(item => {
        if (item.area != "全国") {
          dataList.push(item);
        }
      });
      dataList.forEach(item => {
        item.dataList = "";
        item.dataList = item.hotName + item.area;
        item.dataList1 = item.hotName + "-" + item.area;

        if (
          item.dataList.indexOf(this.searchList) != -1 ||
          item.dataList1 == this.searchList
        ) {
          this.pushSetData.options.forEach(ite => {
            if (item.hostType == ite.type) {
              ite.name1.push(item);
            }
          });
        }
      });
    },
    // 换一换功能
    async changeData(val, index) {
      if (this.carouselData[index].hostList.length == 0) {
        return false;
      }
      val.loading = true;
      let listDate = this.carouselData[index].hostList;
      val.pageNum += 1;
      let url = `?pageNum=${val.pageNum}&pageSize=10`;
      const res = await changeData(
        {
          id: val.id
        },
        url
      );
      if (res.data.rows.length > 0) {
        this.carouselData[index].hostList = res.data.rows;
      } else {
        val.pageNum = 0;
        this.carouselData[index].hostList = listDate;
        return this.changeData(val, index);
      }
      val.loading = false;
    },
    pushSet() {
      this.hotList();
      this.pushSetData.dialogVisible = true;
    },
    removePushSet(index) {
      this.pushSetData.data.val3.splice(index, 1);
    },
    // 获取推送设置列表
    async hotList() {
      this.pushSetData.data.val2 = [];
      this.countryList = [];
      this.pushSetData.options.forEach(ite => {
        ite.name1 = [];
      });
      this.pushSetData.data.val3 = [];
      this.loading = true;
      const res = await hotList({});
      if (res != undefined) {
        //推送列表
        this.everyitem = res.data.data.SetHotList; //推送列表
        this.pushSetData.data.val4 = res.data.data.SetUpTime; //推送时间
        res.data.data.SetUpTime.forEach(item => {
          if (item.flag == 1) {
            this.pushSetData.data.val1 = "2";
            this.pushSetData.data.val2.push(item);
          }
        });
        // 设置的唯一id
        this.pushSetId =
          res.data.data.PushSet != null && res.data.data.PushSet.pushSetId
            ? res.data.data.PushSet.pushSetId
            : 0;
        this.everyitem.forEach(item => {
          if (item.flag == 1) {
            this.pushSetData.data.val3.push(item);
          }
          if (item.area == "全国") {
            this.countryList.push(item);
          }
          this.pushSetData.options.forEach(ite => {
            if (item.hostType == ite.type && item.area != "全国") {
              ite.name1.push(item);
            }
          });
        });
      }
      this.loading = false;
    },
    // 更新按钮
    handleUpate() {
      this.hotListInfo();
    },
    // 每个模块
    async hotListInfo() {
      this.loadingList = true;
      this.carouselData = [];
      const res = await selectList({});
      if (res.data.rows) {
        this.carouselData = res.data.rows;
      }
      this.loadingList = false;
    },
    // 保存按钮
    async editHotList() {
      this.searchList = "";
      let hotListAreaId = []; // 媒体热榜区域名称
      let hotListId = []; //	媒体热榜主键Id
      let hotListType = []; //	热榜类型
      let setPushs = []; //	推送设置
      let data = {
        hotListAreaId: "",
        hotListId: "",
        hotListType: "",
        pushSetId: "",
        setPush: ""
      };
      if (this.pushSetData.data.val3.length != 0) {
        this.pushSetData.data.val3.forEach(item => {
          hotListAreaId.push(item.area);
          hotListId.push(item.hotListId);
          hotListType.push(item.hostType);
        });
        data.hotListAreaId = hotListAreaId.join(",");
        data.hotListId = hotListId.join(",");
        data.hotListType = hotListType.join(",");
      }
      if (this.pushSetData.data.val1 == "1") {
        data.setPush = "";
        this.pushSetData.data.val2 = [];
      } else if (
        this.pushSetData.data.val2.length != 0 &&
        this.pushSetData.data.val1 == "2"
      ) {
        this.pushSetData.data.val2.forEach(item => {
          setPushs.push(item.setId);
        });
        data.setPush = setPushs.join(",");
      }
      if (this.pushSetId != null) {
        data.pushSetId = this.pushSetId;
      }
      const res = await changeInfo(data);
      if (res.data.code == 200) {
        this.$message.success("操作成功！");
      } else {
        this.$message.error("操作失败！");
      }
      this.pushSetData.dialogVisible = false;
      this.hotListInfo();
    },
    // 点击当前行跳转
    handel(web) {
      window.open(web);
    },
    async getHotData(data) {
      let qu = { organId: this.$store.state.user.organizationId, }
      let res = await selectlocal(qu);
      if (res.data.data.localMake == 1) {
        this.hotDataShow = true;
        data = {
          organId: this.$store.state.user.organizationId,
          startTime: this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " 00:00:00",
          endTime: this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " 23:59:59",
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize
        }
        let res = await todayHotRank(data);
        this.hotTableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    gourlPath(url) {
      window.open(url, "_blank");
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getHotData();
    }
  }
};
</script>
<style scoped>
.hot-header {
  width: 100%;
  height: 85px;
  background: #ffffff;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-header-left .hot-header-title {
  font-size: 26px;
  font-weight: bold;
  color: #1a1a1a;
}

.hot-header-left .hot-header-time {
  font-size: 14px;
  color: #666666;
  margin-left: 10px;
}

.hot-header-flush {
  font-size: 14px;
  color: #02bc7c;
  cursor: pointer;
}

.hot-header-flush img {
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.hot-header-set {
  font-size: 14px;
  color: #2e59ec;
  cursor: pointer;
  margin-left: 24px;
}

.hot-header-set img {
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.hot-container {
  min-height: 700px;
  padding: 0px 86px 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.hot-cont {
  margin-right: 25px;
  width: 350px;
  height: 452px;
  background: #ffffff;
  box-shadow: 0px 1px 12px -3px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 24px;
  margin-top: 40px;
}

.carousel-name {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.carousel-name span {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin-left: 8px;
}

.carousel-list {
  width: 100%;
}

.carousel-list-li {
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.carousel-list-li+.carousel-list-li {
  margin-top: 24px;
}

.carousel-list-li img {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.carousel-list-text p {
  font-size: 14px;
  color: #333333;
  width: 205px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.carousel-list-text p+p {
  margin-top: 8px;
  font-size: 12px;
  color: #999999;
}

.carousel-list-text-index {
  font-size: 14px;
  color: #999;
  font-weight: bold;
  margin-right: 11px;
}

.carousel-list-text-li:nth-child(1) .carousel-list-text-index {
  color: #d0021b;
}

.carousel-list-text-li:nth-child(2) .carousel-list-text-index {
  color: #ff2a39;
}

.carousel-list-text-li:nth-child(3) .carousel-list-text-index {
  color: #ff6a2c;
}

.carousel-list-text-title {
  font-size: 14px;
  color: #5a6474;
  width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.carousel-list-text-read {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #b8bcc3;
  width: 70px;
  text-align: right;
}

.carousel-list-text-li {
  display: flex;
  cursor: pointer;
  margin-top: 16px;
}

.container-tab {
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.hot-pushSetData-top {
  width: 100%;
  height: 600px;
  display: flex;
}

.analyse-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img img {
  width: 100%;
  height: 100%;
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.hot-pushSetData-left {
  width: 490px;
  height: 100%;
  margin-right: 28px;
  overflow-y: auto;
  overflow-x: hidden;
}

.hot-pushSetData-right {
  width: 204px;
  height: 100%;
}

.hot-pushSetData-set {
  margin-top: 24px;
}

.hot-pushSetData-set>span {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}

.hot-pushSetData-set>p {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.el-checkbox {
  margin-right: 30px;
}

.hot-pushSetData-left-option {
  margin-top: 24px;
  width: 472px;
}

.hot-pushSetData-ul {
  width: 100%;
  margin-top: 8px;
  height: 330px;
  background: #f4f5fa;
  border-radius: 4px;
  padding: 20px 16px;
}

.hot-pushSetData-ul-li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hot-pushSetData-ul-li+.hot-pushSetData-ul-li {
  margin-top: 12px;
}

.hot-pushSetData-ul-li-name {
  font-size: 14px;
  color: #333333;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-pushSetData-ul-li img {
  cursor: pointer;
}

.option-group {
  width: 100%;
}

.option-group .el-checkbox {
  margin-bottom: 24px;
}

.change-title {
  color: #626675;
  float: right;
  margin-top: 14px;
  cursor: pointer;
}

.carousel-list-img {
  width: 19px;
}

.countryList {
  font-size: 14px;
  color: #333333;
  margin-top: 16px;
  width: 97%;
}

.countryList>div {
  margin-top: 10px;
  padding: 24px 16px 0 16px;
  border-radius: 4px;
  border: 1px solid #e4e6ec;
}

.local-text {
  margin-bottom: 16px;
  font-size: 14px;
  color: #333333;
}

.pushSet {
  margin: 40px 0 8px 0;
  color: #333333;
  font-weight: 600;
}

.pushSet-box {
  margin-top: 8px;
  padding: 16px 0 0 20px;
  background: #f4f5fa;
  border-radius: 4px;
}

.pushSet-box .el-checkbox,
.countryList .el-checkbox {
  margin-bottom: 24px;
}

.pushSet-box .el-checkbox:nth-child(1) {
  margin-right: 38px;
}

.option-group-big {
  height: 250px;
  padding: 24px 0 16px 16px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid #e4e6ec;
}

.option-group-box {
  height: 200px;
  overflow-y: auto;
}

#zb_hotdata_today {
  margin: 60px 86px 0px;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 16px;
  box-shadow: 0px 1px 12px -3px rgba(0, 0, 0, 0.06);
}

.zb_hotdata_title {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  justify-content: space-between;
}

.zb_hotdata_title div {
  display: flex;
  align-items: center;
}

.zb_hotdata_today_table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}


::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 0px;
}

::v-deep .el-table th[class='gutter'] {
  display: none;
  width: 0
}

::v-deep .el-table colgroup col[name='gutter'] {
  display: none;
  width: 0;
}

::v-deep .el-table__body {
  width: 100% !important;
}

.zb_hotdata_today_platform {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zb_hotdata_today_platform img {
  margin-right: 10px;
}

.zb_hotdata_today_title {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.zb_hotdata_today_rank {
  text-align: center;
}

.zb_hotdata_today_Clicks {
  text-align: center;
}

.zb_hotdata_today_time {
  text-align: center;
}

.zb_hotdata_today_history {
  font-size: 14px;
  color: #2e59ec;
  cursor: pointer;
  float: right;
}
</style>
